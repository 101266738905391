<template>
  <div>
    <!-- <Header :url="require('@/assets/images/dynamc.png')" /> -->
    <Header :banner="bannerList" />
    <Menu :menuList="menuList" @fun="typeClick"></Menu>
    <!--Content-->
    <section class="container-fluid container">
      <div class="row">
        <div id="container">
          <!--图片开始-->
          <div class="col-md-3 col-sm-12 mb20" v-for="item in dongtaiList" :key="item.id">
            <div class="thumbnail">
              <a :href="item.link" target="_blank">
                <img :src="item.img" class="img-responsive img-rounded center-block">
              </a>
              <div class="caption cursor" @click="$util.goLink(item)">
                <h4 class="textLeft">{{ item.title }}</h4>
                <p class="dyCon textLeft">
                  <small>{{ item.content }}</small>
                </p>
                <p class="time textLeft mb0">{{ item.time }}</p>
              </div>
            </div>
          </div>
          <!--图片结束-->
        </div>
      </div>
      <div class="row" style="margin-top: 3rem" @click="lookMore()">
        <div class="col dyMore flexCenter cursor">
          查看更多
        </div>
      </div>
    </section>
    <div class="toast-container position-fixed bottom-0 end-0 p-3">
      <div id="liveToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Bootstrap</strong>
          <small>11 mins ago</small>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          Hello, world! This is a toast message.
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
export default {
  name: "about",
  data() {
    return {
      page: 1,
      limit: 15,
      type: 0,
      last_page: '',
      dongtaiList: [],
      bannerList: [],
      menuList: [
        {
          id: 'a1',
          name: '全部',
        }, {
          id: 'a2',
          name: '协会',
        }, {
          id: 'a3',
          name: '会员',
        }, {
          id: 'a4',
          name: '博物馆',
        },
      ]
    }
  },
  methods: {
    //banner
    get_two_banner() {
      this.$get('/api/get_two_banner', { type: 3 }).then(res => {
        this.bannerList = res.data.data;
      }).catch(err => {

      })
    },
    //会员权益
    get_index_dongtai() {
      this.$get('/api/get_index_dongtai', { page: this.page, limit: this.limit, type: this.type }).then(res => {
        
        if(this.page==1){
          this.dongtaiList = res.data.data;
        }else{
          this.dongtaiList = [...this.dongtaiList,...res.data.data];
        }
        this.last_page = res.data.last_page;
      }).catch(err => {

      })
    },
    //分类点击
    typeClick(e) {
      this.type = e.substring(1, 2) * 1 - 1;
      this.page = 1;
      this.get_index_dongtai();
    },
    //点击查看更多
    lookMore() {
      if (this.page == this.last_page) {
        this.$message.warning('已经到底了')
      } else {
        this.page++;
        this.get_index_dongtai()
      }
    }
  },
  mounted() {
    this.get_index_dongtai(0);
    this.get_two_banner();
  }
}
</script>
<style lang="scss" scoped>
.caption {
  background: #fafaf5;
  padding: 20px;
  box-sizing: border-box;

  h4 {
    color: #707070;
    font-weight: normal;
  }

  .dyCon {
    color: #707070;
  }

  .time {
    color: #afafaf;
    font-size: .8rem;
  }
}
</style>
<style>
body {
  --img-width: 300px;
  --img-gap: 5px;
}

@media (max-width: 575.98px) {
  body {
    --img-width: 360px;
    --img-gap: 5px;
  }
}

#container>div {
  width: var(--img-width);
  /* margin: 0 0 70px 0; */
  overflow: auto;
}

.thumbnail>a>img {
  width: 100%;
}

#container {
  column-width: var(--img-width);
  -webkit-column-width: var(--img-width);
  -moz-column-width: var(--img-width);
  -o-column-width: var(--img-width);
  -ms-column-width: var(--img-width);
  column-gap: var(--img-gap);
  -webkit-column-gap: var(--img-gap);
  -moz-column-gap: var(--img-gap);
  -o-column-gap: var(--img-gap);
  -ms-column-gap: var(--img-gap);
}
</style>
